const styles = {
  root: {
    paddingX: { xs: 2, lg: 0 },
    marginTop: 2,
    // marginBottom: 1,
  },

  mainContainer: {
    // border: 1,
    // borderColor: '#E9E9E9',
    borderRadius: 0.5,
    paddingX: { xs: 2, lg: 3 },
    paddingY: { xs: 2, lg: 3 },
    '& .MuiAutocomplete-root': {
      marginRight: '0px!important',
      marginBottom: '0px!important',
    },
  },

  title: {
    // marginTop: 0.5,
    '&.MuiTypography-h2': {
      color: 'text.primary',
    },
    '&.MuiTypography-h3': {
      marginBottom: 1,
      p: 0,
      color: 'text.primary',
    },
  },

  textContainer: {
    '&.row': {
      // paddingRight: 3,
      '& .QuoterTitle-root': {
        paddingRight: { xs: 2, lg: 0 },
      },
    },
  },

  inputsContainer: {
    mt: 1,
    alignItems: 'center',
    justifyContent: 'center',
    '&.row': {
      '& .MuiGrid-item': {
        paddingX: 1,
        marginTop: { xs: 1, lg: 0 },
        // '&:nth-of-type(1)': {
        //   marginTop: { xs: 1, lg: 0 },
        // },
      },
    },
    '&.column': {
      '& .MuiGrid-item': {
        marginTop: { xs: 1, lg: 0 },
      },
    },
  },

  item: {
    // paddingTop: { lg: '12px!important' },
    '& .MuiFormControl-root': {
      paddingY: 0,
      '& .MuiFormLabel-root': {
        fontSize: 12,
        color: 'text.grey300',
      },
      '&:placeholder': {
        fontSize: 12,
        color: '#000!important',
      },
    },
  },

  buttonContainer: {
    display: 'flex',
    alignItems: 'end',
  },

  button: {
    mt: { xs: 1, md: 0 },
    height: '40px',
    '&.column': {
      marginTop: 1,
    },
  },

  field: {
    color: '#000!important',
    '& .MuiAutocomplete-inputRoot': {
      paddingY: '0px!important',
    },
    '& .MuiOutlinedInput-input': {
      paddingY: '12px!important',
    },
    '& ::-webkit-outer-spin-button': {
      display: 'none',
    },
    '& ::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& .MuiOutlinedInput-root': {
      marginBottom: '0px!important',
    },
    '& .MuiInputBase-input': {
      padding: '9.5px 14px!important',
    },
    '&.MuiFormLabel-root': {
      color: '#343434!important',
    },
  },
}

export default styles
