import React, { memo, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import * as yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setValue } from 'redux/modules/common'
import { useQuery } from 'react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { useForm, Controller, useWatch } from 'react-hook-form'
import useMediaQuery from '@mui/material/useMediaQuery'
import { yupResolver } from '@hookform/resolvers/yup'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { setFormValue, setValue as setAppraisalValue } from 'redux/modules/appraisal'
import useUtms from 'hooks/useUtms'
import { addIdProp, addTestIdProp } from 'utils/universal'
import usePageProps from 'hooks/usePageProps'
import sx from './styles'

const Quoter = ({
  title,
  description,
  variant = 'row', // column, rowMObile
  buttonId = 'vta_cotizador_modal',
  buttonLabel = 'GET_YOUR_OFFER',
  sx: sxProp,
  isVTA = false,
  testId: testIdProp,
  variantTitle = 'h3',
  disclaimer,
  rowTitle,
  isMain = false,
}) => {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const router = useRouter()
  const query = useUtms()
  const { defaultYears } = usePageProps()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [yearOptions, setYearOptions] = useState(defaultYears)

  const {
    control,
    setValue: setValueForm,
    handleSubmit,
    formState,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        year: yup.string().required(),
        make: yup.string().required(),
      }),
    ),
    defaultValues: {
      year: '',
      make: '',
    },
    mode: 'onChange',
  })

  const [year, make] = useWatch({
    control,
    name: ['year', 'make'],
  })

  const onSubmit = () => {
    const modelStep = f({ id: 'ROUTE_QUOTE_CAR_STEP_MODEL' })
    const easyModelStep = f({ id: 'ROUTE_QUOTE_CAR_STEP_MODEL_EASY' })
    const nextStep = year <= 2011 ? easyModelStep : modelStep
    const nextLink = f({ id: 'ROUTE_QUOTE_CAR' }, { step: nextStep })
    dispatch(
      setValue({
        key: 'urlToBack',
        value: router?.asPath,
      }),
    )
    if (year <= 2011) {
      dispatch(setAppraisalValue({ key: 'leadType', value: 'easy', persist: true }))
    }
    router.push(`${nextLink}${query}`)
  }

  const makesQuery = useQuery(
    [year, 'makes'],
    async () => {
      const req = await axios.get(
        `${process.env.NEXT_PUBLIC_API_ROOT}/api/vehicle/makes?origin_source=cat_vehicle`,
        {
          params: {
            year,
          },
        },
      )
      const vehicleMaker = [...new Set(req.data)]
      return vehicleMaker
    },
    { enabled: Boolean(year) },
  )

  const handleUpdateYear = (value) => {
    setValueForm('year', value, { shouldValidate: true })
    dispatch(setFormValue({ key: 'year', value }))
    setValueForm('make', '', { shouldValidate: true })
    dispatch(setFormValue({ key: 'make', value: '' }))
  }

  const handleUpdateMake = (value) => {
    setValueForm('make', value, { shouldValidate: true })
    dispatch(setFormValue({ key: 'make', value }))
  }

  useEffect(() => {
    if (defaultYears?.length > 0) {
      setYearOptions(yearOptions)
    }
  }, [])

  return (
    <Box
      sx={{ ...sx.root, ...sxProp }}
      {...addTestIdProp(testIdProp)}
      {...addIdProp(testIdProp)}
    >
      <Container
        maxWidth="lg"
        sx={sx.mainContainer}
      >
        <Grid
          container
          direction={variant}
        >
          {(title || description) && (
            <Grid
              item
              xs={12}
              lg={12}
              sx={sx.textContainer}
              className={clsx({
                row: variant === 'row',
              })}
            >
              {title && (
                <Typography
                  variant={variantTitle}
                  sx={sx.title}
                  className="QuoterTitle-root"
                >
                  <FormattedMessage id={title} />
                </Typography>
              )}
              {description && (
                <Typography
                  variant="body2"
                >
                  <FormattedMessage id={description} />
                </Typography>
              )}
            </Grid>
          )}
          <Grid
            item
            container
            xs={12}
            lg={12}
            spacing={isMobile ? 0 : 1}
            sx={sx.inputsContainer}
            className={clsx({
              row: variant === 'row',
              column: variant === 'column',
            })}
          >
            {rowTitle && (
              <Grid
                item
                xs={12}
                lg={variant === 'row' ? (rowTitle ? 3.5 : 4) : isVTA ? 6 : 12}
                sx={{ textAlign: 'left' }}
              >
                <Typography
                  variant={variantTitle}
                  sx={sx.title}
                  className="QuoterTitle-root"
                >
                  <FormattedMessage id={rowTitle} />
                </Typography>
              </Grid>
            )}

            <Grid
              item
              xs={variant === 'row' ? 6 : 12}
              lg={variant === 'row' ? (rowTitle ? 3 : 4) : isVTA ? 6 : 12}
              sx={sx.item}
            >
              <Controller
                name="year"
                control={control}
                render={({ fieldState }) => (
                  <Autocomplete
                    className={clsx('autocompleteCustom', { touched: year })}
                    noOptionsText={f({ id: 'HOME_NO_OPTIONS' })}
                    options={yearOptions || []}
                    id="combo-year"
                    onChange={(event, newValue) => {
                      handleUpdateYear(newValue)
                    }}
                    // filterOptions={(options, params) => handleFilterOptions(options, params)}
                    renderOption={(props, option) => (
                      <li {...{ ...props, id: `combo-year-${option}` }}>
                        {option}
                      </li>
                    )}
                    autoComplete
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          sx={sx.field}
                          type={isMobile ? 'tel' : 'number'}
                          error={fieldState.invalid}
                          placeholder={f({ id: 'VTA_YEAR_OPTIONS' })}
                        />
                      )
                    }}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={variant === 'row' ? 6 : 12}
              lg={variant === 'row' ? (rowTitle ? 3 : 4) : isVTA ? 6 : 12}
              sx={sx.item}
            >
              <Controller
                name="make"
                control={control}
                render={({ fieldState }) => (
                  <Autocomplete
                    value={make}
                    options={makesQuery?.data?.sort() || []}
                    className={clsx({ touched: make })}
                    noOptionsText={f({ id: 'HOME_NO_OPTIONS' })}
                    disabled={!year}
                    renderOption={(props, option) => (
                      <li {...{ ...props, id: `combo-maker-${option.toLowerCase()}` }}>
                        {option}
                      </li>
                    )}
                    onChange={(event, newValue) => {
                      handleUpdateMake(newValue)
                    }}
                    id="combo-make"
                    clearOnBlur
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={sx.field}
                        error={fieldState.invalid}
                        placeholder={f({ id: 'VTA_MAKER_OPTIONS' })}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              lg={variant === 'row' ? (rowTitle ? 2.5 : 4) : 12}
              sx={sx.buttonContainer}
            >
              <Button
                id={buttonId}
                sx={sx.button}
                style={{ marginTop: (isMain ? '16px' : (isMobile ? '16px' : '0px')) }}
                fullWidth
                className={clsx({ column: variant === 'column' && !isVTA })}
                color="info"
                variant="special"
                onClick={handleSubmit(onSubmit)}
                disabled={!formState.isValid}
                aria-label={f({ id: buttonLabel })}
                size="large"
              >
                <FormattedMessage id={buttonLabel} />
              </Button>
            </Grid>

            {disclaimer && (
              <Grid
                item
                xs={12}
                align="center"
              >
                <Typography
                  variant="quote"
                  component="p"
                >
                  <FormattedMessage id={disclaimer} />
                </Typography>
              </Grid>
            )}

          </Grid>
        </Grid>

      </Container>
    </Box>
  )
}

export default memo(Quoter)
